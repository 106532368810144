import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance.js";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession.js";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes.js";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/Context.tsx";

export default function SubjectWiseGradingForAllSchool() {
  const context = useContext(AppContext);
  const [subjectWiseGrading, setSubjectWiseGrading] = useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const { schools } = useSession();
  const navigate = useNavigate();

  const getSubjectWiseGradingForAllSchool = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/get-subject-wise-grading-for-all-school"
        );
        console.log("SubjectWiseGradingForAllSchool", data.data);

        if (status === 200) {
          setSubjectWiseGrading(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubjectWiseGradingForAllSchool();
  }, [schools]);

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("subject-wise-grading-for-all-school.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "admin-report.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [
      { A: "Subject Wise Grading For All School - Admin Report" },
      {},
    ];

    let table = [
      {
        A: "S.No",
        B: "School Name",
        C: "Subject Name",
        D: "Total Students",
        E: "A1",
        F: "A",
        G: "B",
        H: "C",
        I: "Fail",
      },
    ];

    subjectWiseGrading.length > 0 &&
      subjectWiseGrading.forEach((item, index) => {
        table.push({
          A: index + 1,
          B: item.NameOfSchool,
          C: item.SubjectName,
          D: item.TotalStudents,
          E: item.A1,
          F: item.A,
          G: item.B,
          H: item.C,
          I: item.Fail,
        });
      });
    table = [{}].concat(table).concat([""]);
    const finalData = [...title, ...table];
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, sheet, "admin-report");
    const workbookBlob = workbook2Blob(wb);
    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:I2",
      tbodyRange: `A3:I${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:Q${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(15);
        sheet.column("B").width(15);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(15);
        sheet.column("I").width(15);
        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  const sortedData = subjectWiseGrading.reduce((acc, curr) => {
    const { NameOfSchool } = curr;
    if (!acc.has(NameOfSchool)) {
      acc.set(NameOfSchool, []);
    }
    acc.get(NameOfSchool).push(curr);
    return acc;
  }, new Map());

  return (
    <Box flexGrow={1} sx={{ width: "100%" }}>
      <Stack
        sx={{ mb: 2, width: "100%" }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          sx={{
            width: "100%",
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
          direction="row"
        >
          <Typography variant="h6" component="h1">
            Subject Wise Grading For All School
          </Typography>

          <LoadingButton
            type="submit"
            onClick={() => {
              navigate(-1);
            }}
            variant="contained"
            color="primary"
          >
            Back to Admin Dashbaord
          </LoadingButton>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <LoadingButton
            type="submit"
            onClick={() => {
              let quitSchoolData = [];
              subjectWiseGrading.forEach((item, index) => {
                quitSchoolData.push({
                  "S.No": index + 1,
                  "School Name": item.NameOfSchool,
                  "Subject Name": item.SubjectName,
                  "Total Students": item.TotalStudents,
                  A1: item.A1,
                  A: item.A,
                  B: item.B,
                  C: item.C,
                  Fail: item.Fail,
                });
              });
              context.dispatch({
                Payload: { dataForPivotTable: [...quitSchoolData] },
                Type: "DATA FOR PIVOT TABLE",
              });
              navigate("/reports/react-pivot-table");
            }}
            fullWidth
            variant="contained"
            color="primary"
          >
            Visualizer{" "}
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="subject-wise grading table"
              id="grading-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="center">School Name</TableCell>
                  <TableCell align="center">Subject Name</TableCell>
                  <TableCell align="center">Total Students</TableCell>
                  <TableCell align="center">A1</TableCell>
                  <TableCell align="center">A</TableCell>
                  <TableCell align="center">B</TableCell>
                  <TableCell align="center">C</TableCell>
                  <TableCell align="center">Fail</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(sortedData.entries()).map(
                  ([school, subjects], schoolIndex) => (
                    <React.Fragment key={school}>
                      {subjects.map((subject, subjectIndex) => (
                        <TableRow
                          key={`${school}-${subject.SubjectName}`}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{subjectIndex + 1}</TableCell>
                          <TableCell align="center">
                            {subjectIndex === 0 ? subject.NameOfSchool : ""}
                          </TableCell>
                          <TableCell align="center">
                            {subject.SubjectName}
                          </TableCell>
                          <TableCell align="center">
                            {subject.TotalStudents}
                          </TableCell>
                          <TableCell align="center">{subject.A1}</TableCell>
                          <TableCell align="center">{subject.A}</TableCell>
                          <TableCell align="center">{subject.B}</TableCell>
                          <TableCell align="center">{subject.C}</TableCell>
                          <TableCell align="center">{subject.Fail}</TableCell>
                        </TableRow>
                      ))}

                      {/* Total Row */}
                      <TableRow
                        key={`${school}-totals`}
                        sx={{
                          backgroundColor: "#f0f0f0",
                          fontWeight: "bold",
                        }}
                      >
                        <TableCell
                          colSpan={2}
                          align="center"
                          sx={{ fontWeight: "bold" }}
                        >
                          {`Total for ${school}`}
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          {subjects.reduce(
                            (sum, subject) =>
                              sum + (subject.TotalStudents || 0),
                            0
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subjects.reduce(
                            (sum, subject) => sum + (subject.A1 || 0),
                            0
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subjects.reduce(
                            (sum, subject) => sum + (subject.A || 0),
                            0
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subjects.reduce(
                            (sum, subject) => sum + (subject.B || 0),
                            0
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subjects.reduce(
                            (sum, subject) => sum + (subject.C || 0),
                            0
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {subjects.reduce(
                            (sum, subject) => sum + (subject.Fail || 0),
                            0
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                )}
                {/* Grand Total Row */}
                <TableRow
                  sx={{
                    backgroundColor: "#d0d0d0",
                    fontWeight: "bold",
                  }}
                >
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Grand Total
                  </TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    {Array.from(sortedData.entries()).reduce(
                      (grandSum, [, subjects]) =>
                        grandSum +
                        subjects.reduce(
                          (sum, subject) => sum + (subject.TotalStudents || 0),
                          0
                        ),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {Array.from(sortedData.entries()).reduce(
                      (grandSum, [, subjects]) =>
                        grandSum +
                        subjects.reduce(
                          (sum, subject) => sum + (subject.A1 || 0),
                          0
                        ),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {Array.from(sortedData.entries()).reduce(
                      (grandSum, [, subjects]) =>
                        grandSum +
                        subjects.reduce(
                          (sum, subject) => sum + (subject.A || 0),
                          0
                        ),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {Array.from(sortedData.entries()).reduce(
                      (grandSum, [, subjects]) =>
                        grandSum +
                        subjects.reduce(
                          (sum, subject) => sum + (subject.B || 0),
                          0
                        ),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {Array.from(sortedData.entries()).reduce(
                      (grandSum, [, subjects]) =>
                        grandSum +
                        subjects.reduce(
                          (sum, subject) => sum + (subject.C || 0),
                          0
                        ),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {Array.from(sortedData.entries()).reduce(
                      (grandSum, [, subjects]) =>
                        grandSum +
                        subjects.reduce(
                          (sum, subject) => sum + (subject.Fail || 0),
                          0
                        ),
                      0
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
