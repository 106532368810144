import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Menu,
  MenuItem,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
// import Card from "./Quicks/Card";
import { LoadingButton } from "@mui/lab";
import GroupIcon from "@mui/icons-material/Group";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import axiosInstance from "../Api/axios.instance";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "../Components/Dashboards/Quicks/Card";
import GenderRatio from "../Components/Charts/GenderRatio";
import ActiveSchoolCount from "../Components/Charts/ActiveSchoolCountAdmin";
import StudentStrengthAdmin from "../Components/Charts/StudentStrengthAdmin";
import useSession from "../CustomHooks/useSession";
import SchoolWiseClassStrength from "../Components/Charts/SchoolWiseClassStrength";
import SevenDaysAttendance from "../Components/Charts/SevenDaysAttendance";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BadgeIcon from "@mui/icons-material/Badge";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import Modal from "@mui/material/Modal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import SchoolWiseClassStrengthAdmin from "../Components/Charts/SchoolWiseClassStrengthAdmin";
import YearWisePassoutStatsAdmin from "../Components/Charts/YearWisePassoutStatsAdmin";
import ActiveStudentClassWiseOccupationAdmin from "../Components/Charts/ActiveStudentClassWiseOccupationAdmin";
import NewAdmissionPerfomanceinCurrentSessionAdmin from "../Components/Charts/NewAdmissionPerfomanceinCurrentSessionAdmin";
import CAPStats from "./AdminReports/CAPStats";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard(props) {
  const { schools } = useSession();
  const [schoolList, setSchoolList] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [childrenCount, setChildrenCount] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState({});
  const [selectedSchool, setSelectedSchool] = useState({});
  const [data, setData] = useState(null);
  const [classStrengthData, setClassStrengthData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [sid, setSid] = useState(0);
  const navigate = useNavigate();
  const [adminDashboardValues, setAdminDashboardValues] = useState([]);
  const [adminDashboardPTMCountValues, setAdminDashboardPTMCountValues] = useState([]);
  const [adminDashboardNewAdmissionStat, setAdminDashboardNewAdmissionStat] = useState([]);
  const [genderCountRegionBased, setGenderCountRegionBased] = useState([]);
  const [parentCountRegionBased, setParentCountRegionBased] = useState([]);
  const [parentCountAllSchool, setParentCountAllSchool] = useState([]);
  const [missingNICAllSchool, setMissingNICAllSchool] = useState([]);
  const [activeSchoolCountOwnerShipWise, setActiveSchoolCountOwnerShipWise] =
    useState([]);
  const [yearwisePassoutStats, setYearwisePassoutStats] = useState([]);
  const [
    activeStudentClassWiseOccupation,
    setActiveStudentClassWiseOccupation,
  ] = useState([]);
  const [
    newAdmissionPerformanceinCurrentSession,
    setNewAdmissionPerformanceinCurrentSession,
  ] = useState([]);

  const { accesLevel, access, currentPage } = useSession();
  const [formData, setFormData] = useState({
    RegionId: "",
    SchoolId: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      const schoolName = localStorage.getItem("NameOfSchool");
      if (schoolName) {
        setSchoolName(schoolName);
      }
    }
  }, []);
  useEffect(() => {
    const accessObject = Object.assign({}, access);
    const regionId =
      Object.keys(accessObject).length > 0 ? accessObject[0].RegionId : false;
    const schoolId =
      Object.keys(accessObject).length > 0 ? accessObject[0].SchoolId : false;
    if (
      (accesLevel === "ADMIN" || accesLevel === "CLUSTER") &&
      regionId === false &&
      schoolId === false
    ) {
      setOpen(true);
    } else if (
      (accesLevel === "ADMIN" || accesLevel === "CLUSTER") &&
      regionId !== false &&
      schoolId !== false
    ) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          //console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    getAdminDashboardValues();
    getAdminDashboardPTMCount();
    getAdminDashboardNewAdmissionStat();
    getGenderCountByRegion();
    getParentCountByRegion();
    getParentCountAllSchool();
    getMissingNICAllSchool();
    getActiveSchoolCountOwnerWise();
    getYearWisePassoutStats();
    getActiveStudentClassWiseOccupation();
    getNewAdmissionPerformanceinCurrentSession();
  }, [schools]);

  const getAdminDashboardValues = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "ADMIN") {
        const searchParams = new URLSearchParams();
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/admin-dashboard",
          { params: searchParams }
        );
        console.log("AdminDashboardValues", data.data[0][0]);
        if (status === 200) {
          setAdminDashboardValues(data.data[0][0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminDashboardPTMCount = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "ADMIN") {
        const searchParams = new URLSearchParams();
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/ptm-parent-attendance",
          { params: searchParams }
        );
        console.log("adminDashboardPTMCountValues", data.data[0][0]);
        if (status === 200) {
          setAdminDashboardPTMCountValues(data.data[0][0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminDashboardNewAdmissionStat = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let prevYear = year - 1;

    try {
      if (accesLevel === "ADMIN") {
        const searchParams = new URLSearchParams();
        searchParams.append("session", `${prevYear}-${year}`);
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/new-admission-stats",
          { params: searchParams }
        );
        console.log("adminDashboardNewAdmissionStat", data.data[0][0]);
        if (status === 200) {
          setAdminDashboardNewAdmissionStat(data.data[0][0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGenderCountByRegion = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/gender-count-by-region"
        );
        console.log("GenderCountByRegion", data.data[0]);
        if (status === 200) {
          setGenderCountRegionBased(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getParentCountByRegion = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/parents-count-by-region"
        );
        console.log("ParentCountByRegion", data.data[0]);
        if (status === 200) {
          setParentCountRegionBased(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getParentCountAllSchool = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/cap-parent-count-current-month-all-school"
        );
        console.log("ParentCountAllSchool", data.data[0]);
        if (status === 200) {
          setParentCountAllSchool(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMissingNICAllSchool = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/missing-nic-all-school"
        );
        console.log("MissingNICAllSchool", data.data);

        if (status === 200) {
          let returnedObj = Object.values(data.data[0][0])[0];
          console.log("returnedObj", returnedObj);

          let mCount = returnedObj
            .split(":")[1]
            .split(",")[0]
            .replaceAll('"', "");
          console.log("mCount", mCount);

          setMissingNICAllSchool(mCount);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveSchoolCountOwnerWise = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/active-school-wise-count-ownershipwise"
        );
        console.log("ActiveSchoolCountOwnerWise", data.data[0]);
        if (status === 200) {
          setActiveSchoolCountOwnerShipWise(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getYearWisePassoutStats = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/active-student-class-wise-occupation"
        );
        console.log("Active Student Class Wise Occupation", data.data[0]);
        if (status === 200) {
          setActiveStudentClassWiseOccupation(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveStudentClassWiseOccupation = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/yearwise-passout-stats"
        );
        console.log("YearWisePassoutStats", data.data[0]);
        if (status === 200) {
          setYearwisePassoutStats(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNewAdmissionPerformanceinCurrentSession = async () => {
    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/new-admission-performance-in-current-session"
        );
        console.log("NewAdmissionPerfomanceinCurrentSession", data.data[0]);
        if (status === 200) {
          setNewAdmissionPerformanceinCurrentSession(data.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function extractKeys(array) {
    return array.map((item) => {
      const newItem = { ...item };

      Object.keys(newItem).forEach((key) => {
        if (Array.isArray(newItem[key])) {
          // Extract keys from nested arrays
          newItem[key].forEach((nestedItem) => {
            Object.keys(nestedItem).forEach((nestedKey) => {
              newItem[nestedKey] = nestedItem[nestedKey];
            });
          });
          delete newItem[key];
        }
      });

      return newItem;
    });
  }

  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    //console.log("cluster", cluster);
    //console.log("schools", cluster[0].School);
    setSelectedCluster({
      CityId: cluster[0].CityId,
      ClosedDate: cluster[0].ClosedDate,
      CreatedDate: cluster[0].CreatedDate,
      DataEntryOperator: cluster[0].DataEntryOperator,
      DateDataEntry: cluster[0].DateDataEntry,
      Reason: cluster[0].Reason,
      RegionId: cluster[0].RegionId,
      RegionName: cluster[0].RegionName,
      RegionOrder: cluster[0].RegionOrder,
      Status: cluster[0].Status,
    });
    setSchoolList(cluster[0].School);
    setFormData((prevState) => {
      return {
        ...prevState,
        Cluster: selectedCluster,
        RegionId: e.target.value,
      };
    });
  };

  const filteredRegionData = parentCountRegionBased.find(
    (region) => region.RegionName === selectedCluster
  );

  const _handleOnChange = (e) => {
    const school = schoolList.filter(
      (item) => item.SchoolId === e.target.value
    );
    localStorage.setItem("NameOfSchool", school[0].NameOfSchool);
    setSelectedSchool({
      SchoolId: school[0].SchoolId,
      NameOfSchool: school[0].NameOfSchool,
    });
    setFormData((prevState) => {
      return {
        ...prevState,
        School: selectedSchool,
        SchoolId: e.target.value,
      };
    });
  };

  const _onContinue = (e) => {
    e.preventDefault();
    //console.log("Form Data", formData);
    //console.log("selectedCluster", selectedCluster);
    //console.log("selectedSchool", selectedSchool);
    const schoolData = [
      {
        Cluster: selectedCluster,
        RegionId: formData.RegionId,
        School: selectedSchool,
        SchoolId: formData.SchoolId,
        // UserAccessID: 138,
      },
    ];
    //console.log("schoolData", schoolData);
    if (schoolData.SchoolId !== null) {
      window.localStorage.setItem("UserAccess", JSON.stringify(schoolData));
      setOpen(false);
      window.location.reload();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    height: "80%",
    marginTop: "10%",
    marginLeft: "10%",
    marginRight: "10%",
    backgroundColor: "#ffffff",
    border: "none !important",
    p: 3,
  };

  const cellStyle = {
    fontSize: "15px",
    border: "0px",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Admin Dashboard Heading and Admin Reports Dropdown */}
      <Stack
        sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
        direction="row"
      >
        <Typography variant="h5" component="h1">
          Admin Dashboard
        </Typography>
        

        {/* Dropdown for Admin Reports */}
        <Button
          aria-controls="admin-reports-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ExpandMoreIcon />}
          sx={{ color: "black" }}
        >
          Admin Reports
        </Button>
        <Menu
          id="admin-reports-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleDropdownClose}
        >
          <MenuItem
            onClick={() => {
              navigate("/capstats");
            }}
          >
            CAP Stats
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/ptmstats");
            }}
          >
            PTM Stats
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/paid-fee-summary");
            }}
          >
            Paid Fee Summary
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/school-observation-schoolwise-monthwise");
            }}
          >
            School Observation
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/cocurricular-schoolwise-yearwise-perfomance");
            }}
          >
            CoCurricular Observation
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/get-subject-wise-grading-for-all-clusters");
            }}
          >
            Subject Wise Grading For Cluster
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/get-subject-wise-grading-for-all-school-of-selected-cluster");
            }}
          >
            Subject Wise Grading For All School Of Selected Cluster
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/get-subject-wise-grading-for-all-classes-of-selected-cluster");
            }}
          >
            Subject Wise Grading For All Classes Of Selected Cluster
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/get-subject-wise-grading-for-all-classes-of-selected-school");
            }}
          >
            Subject Wise Grading For All Classes Of Selected Cluster And School
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/get-subject-wise-grading-for-all-school");
            }}
          >
            Subject Wise Grading For School
          </MenuItem>
        </Menu>
      </Stack>

      {/* Tiles */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardValues?.["ActiveStudent_Count"] ?? 0}
            text="Active Student"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardValues?.["Cluster_Count"] ?? 0}
            text="Cluster Count"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardValues?.["Orphan_Count"] ?? 0}
            text="Orphan"
            icon={<PeopleAltIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardValues?.["StudentAgeBelowFour_count"] ?? 0}
            text="Student Age Below Four"
            icon={<GroupIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardValues?.["Teacher_Count"] ?? 0}
            text="No of Teacher"
            icon={<BadgeIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardValues?.["UnderPrivileged_Count"] ?? 0}
            text="Under Privileged Student"
            icon={
              <AdminPanelSettingsIcon
                fontSize="large"
                sx={{ color: "white" }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardValues?.["dropOut_Count"] ?? 0}
            text="Dropout Students"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={parentCountAllSchool?.["ParentsCount"] ?? 0}
            text=" CAP Parent Count All School"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={missingNICAllSchool}
            text="Missing NIC All School"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardPTMCountValues?.["ParentCount"] ?? 0}
            text="PTM"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Card
            cardProps={{
              sx: {
                mb: 1,
                backgroundColor: "#27ae60",
              },
            }}
            counter={adminDashboardNewAdmissionStat?.["NewAdmision"] ?? 0}
            text="New Admission Stats"
            icon={<CreditCardIcon fontSize="large" sx={{ color: "white" }} />}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>Cluster Wise Gender Proportion</h3>
            <StudentStrengthAdmin yearStrength={genderCountRegionBased} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={12}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>Student Parent Count Region Wise</h3>
            <SchoolWiseClassStrengthAdmin
              classStrength={parentCountRegionBased}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>Year Wise Passout Stats</h3>
            <YearWisePassoutStatsAdmin classStrength={yearwisePassoutStats} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>Active Student Class Wise Occupation</h3>
            <ActiveStudentClassWiseOccupationAdmin
              classStrength={activeStudentClassWiseOccupation}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper square sx={{ padding: "10px" }}>
            <h3>New Admission Performance in Current Session</h3>
            <NewAdmissionPerfomanceinCurrentSessionAdmin
              classStrength={newAdmissionPerformanceinCurrentSession}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper square sx={{ padding: 10, height: "100%" }}>
            <h3>Student Gender Ratio</h3>
            <GenderRatio
              genderRatio={
                [
                  adminDashboardValues?.Male_Count,
                  adminDashboardValues?.Female_Count,
                ] ?? null
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper square sx={{ padding: 10, height: "100%" }}>
            <h3>
              Active School Count:{" "}
              {activeSchoolCountOwnerShipWise.length > 0
                ? activeSchoolCountOwnerShipWise[0]["TotalSum"]
                : ""}
            </h3>
            <ActiveSchoolCount
              activeSchoolCount={[[...activeSchoolCountOwnerShipWise]] ?? []}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
