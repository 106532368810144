import Axios from "axios";

// export const baseURL = "http://localhost:3000";
// export const baseURL = "https://5ac8-119-152-224-68.eu.ngrok.io";
// export const baseURL = "http://95.216.144.108:3000";
export const baseURL = "https://baithakbackend.briskteck.com.pk";
// export const baseURL = "http://192.168.8.103:3000/";
// export const MinioUrl = "http://95.216.144.108:9000";
export const MinioUrl = "https://baithakminio.briskteck.com.pk";
export default Axios.create({
  baseURL: baseURL,
});