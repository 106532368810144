import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default function StudentsByGradeClusterWise() {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [results, setResults] = useState([]);
  const [subjectVar, setSubjectVar] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [formData, setFormData] = useState({
    test_type: "",
    session: "",
  });

  useEffect(() => {
    _fetchClasses();
  }, []);

  const _fetchClasses = async () => {
    try {
      const { data } = await axiosInstance.get("/classes");
      setClasses(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ===>", formData);
    console.log("Students : ", students);
    const searchParams = new URLSearchParams();
    searchParams.append("session", formData.session);
    searchParams.append("test_type", formData.test_type);

    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-by-grade-and-cluster-wise",
          {
            params: searchParams,
          }
        );
        const outputObject = {};

        console.log("data.data[0]", data.data[0]);

        console.log("data.data[0]", data.data[0]);
        const nonZeroResult = data.data[0].map((result) => {
          return {
            Regionname: result.Regionname,
            Male: result.MaleCount == null ? 0 : result.MaleCount,
            Female: result.FemaleCount == null ? 0 : result.FemaleCount,
            Percentage: result.Percentage,
          };
        });

        console.log("nonZeroResult", nonZeroResult);

        nonZeroResult.forEach((item) => {
          const { Regionname, Male, Female, Percentage } = item;

          if (!outputObject[Regionname]) {
            outputObject[Regionname] = {};
          }

          console.log("Male", parseInt(Male));
          console.log("Male", parseInt(Female));

          outputObject[Regionname][
            `Grade${Percentage}Male`.split(" ").join("")
          ] = parseInt(Male);
          outputObject[Regionname][
            `Grade${Percentage}Female`.split(" ").join("")
          ] = parseInt(Female);
          outputObject[Regionname][
            `Total${Percentage}Students`.split(" ").join("")
          ] = parseInt(Male) + parseInt(Female);
        });

        const outputArray = [];

        for (const region in outputObject) {
          const data = outputObject[region];
          const regionData = { regionName: region, ...data };
          outputArray.push(regionData);
        }

        console.log("outputArray", outputArray);
        if (status === 200) {
          setResults(outputArray);
        }
      }
      if (accesLevel === "SCHOOL") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-by-grade-and-cluster-wise",
          {
            params: searchParams,
          }
        );
        const outputObject = {};
        console.log("data.data[0]", data.data[0]);

        console.log("data.data[0]", data.data[0]);
        const nonZeroResult = data.data[0].map((result) => {
          return {
            Regionname: result.Regionname,
            Male: result.MaleCount == null ? 0 : result.MaleCount,
            Female: result.FemaleCount == null ? 0 : result.FemaleCount,
            Percentage: result.Percentage,
          };
        });

        console.log("nonZeroResult", nonZeroResult);

        nonZeroResult.forEach((item) => {
          const { Regionname, Male, Female, Percentage } = item;

          if (!outputObject[Regionname]) {
            outputObject[Regionname] = {};
          }

          console.log("Male", parseInt(Male));
          console.log("Male", parseInt(Female));

          outputObject[Regionname][
            `Grade${Percentage}Male`.split(" ").join("")
          ] = parseInt(Male);
          outputObject[Regionname][
            `Grade${Percentage}Female`.split(" ").join("")
          ] = parseInt(Female);
          outputObject[Regionname][
            `Total${Percentage}Students`.split(" ").join("")
          ] = parseInt(Male) + parseInt(Female);
        });

        const outputArray = [];

        for (const region in outputObject) {
          const data = outputObject[region];
          const regionData = { regionName: region, ...data };
          outputArray.push(regionData);
        }

        console.log("outputArray", outputArray);
        if (status === 200) {
          setResults(outputArray);
        }
      }
      if (accesLevel === "CLUSTER") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-by-grade-and-cluster-wise",
          {
            params: searchParams,
          }
        );
        const outputObject = {};

        console.log("data.data[0]", data.data[0]);

        console.log("data.data[0]", data.data[0]);
        const nonZeroResult = data.data[0].map((result) => {
          return {
            Regionname: result.Regionname,
            Male: result.MaleCount == null ? 0 : result.MaleCount,
            Female: result.FemaleCount == null ? 0 : result.FemaleCount,
            Percentage: result.Percentage,
          };
        });

        console.log("nonZeroResult", nonZeroResult);

        nonZeroResult.forEach((item) => {
          const { Regionname, Male, Female, Percentage } = item;

          if (!outputObject[Regionname]) {
            outputObject[Regionname] = {};
          }

          console.log("Male", parseInt(Male));
          console.log("Male", parseInt(Female));

          outputObject[Regionname][
            `Grade${Percentage}Male`.split(" ").join("")
          ] = parseInt(Male);
          outputObject[Regionname][
            `Grade${Percentage}Female`.split(" ").join("")
          ] = parseInt(Female);
          outputObject[Regionname][
            `Total${Percentage}Students`.split(" ").join("")
          ] = parseInt(Male) + parseInt(Female);
        });

        const outputArray = [];

        for (const region in outputObject) {
          const data = outputObject[region];
          const regionData = { regionName: region, ...data };
          outputArray.push(regionData);
        }

        console.log("outputArray", outputArray);
        if (status === 200) {
          setResults(outputArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      console.log("subjects", data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        from: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        to: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  // function calculateGrade(percentage) {
  //   if (percentage >= 90) {
  //     return "A+";
  //   } else if (percentage >= 80) {
  //     return "A";
  //   } else if (percentage >= 70) {
  //     return "B";
  //   } else if (percentage >= 60) {
  //     return "C";
  //   } else if (percentage >= 50) {
  //     return "D";
  //   } else {
  //     return "F";
  //   }
  // }

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("Exam-Details-Cluster-Wise-Grade-Summary.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "Exam-Details-Cluster-Wise-Grade-Summary.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Exam Details Cluster Wise Grade Summary" }, {}];

    let table = [
      {
        A: "S.No",
        B: "Cluster Name",
        C: "",
        D: "",
        E: "",
        F: "",
        G: "",
        H: "",
        I: "",
        J: "",
        K: "",
        L: "",
        M: "",
        N: "",
        O: "",
        P: "",
        Q: "",
        S: "",
      },
    ];

    table.push({
      A: "",
      B: "",
      C: "",
      D: "",
      E: "",
      F: "",
      G: "",
      H: "",
      I: "",
      J: "",
      K: "",
      L: "",
      M: "",
      N: "",
      O: "",
      P: "",
      Q: "",
    });

    results.length > 0 &&
      results.forEach((result, index) => {
        table.push({
          A: index + 1,
          B: result.regionName,
          C: result.GradeA1Male,
          D: result.GradeA1Female,
          E: result.TotalA1Students,
          F: result.GradeAMale,
          G: result.GradeAFemale,
          H: result.TotalAStudents,
          I: result.GradeBMale,
          J: result.GradeBFemale,
          K: result.TotalBStudents,
          L: result.GradeCMale,
          M: result.GradeCFemale,
          N: result.TotalCStudents,
          O: result.GradeFAILMale,
          P: result.GradeFAILFemale,    
          Q: result.TotalFAILStudents,
          S:
            result.TotalA1Students +
            result.TotalAStudents +
            result.TotalBStudents +
            result.TotalCStudents +
            result.TotalFAILStudents,
        });
      });

    table = [{}].concat(table).concat([""]);

    const finalData = [...title, ...table];
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    const merges = [
      { s: { r: 3, c: 2 }, e: { r: 3, c: 4 } },
      { s: { r: 3, c: 5 }, e: { r: 3, c: 7 } },
      { s: { r: 3, c: 8 }, e: { r: 3, c: 10 } },
      { s: { r: 3, c: 11 }, e: { r: 3, c: 13 } },
      { s: { r: 3, c: 14 }, e: { r: 3, c: 16 } },
      { s: { r: 3, c: 17 }, e: { r: 3, c: 19 } },
      { s: { r: 3, c: 20 }, e: { r: 3, c: 22 } },
      { s: { r: 3, c: 23 }, e: { r: 3, c: 25 } },
      { s: { r: 3, c: 26 }, e: { r: 3, c: 26 } },
    ];

    sheet["!merges"] = merges;
    sheet["C4"] = {
      v: "A1",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["F4"] = {
      v: "A",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["I4"] = {
      v: "B",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["L4"] = {
      v: "C",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["O4"] = {
      v: "FAIL",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["R4"] = {
      v: "Total",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["C5"] = {
      v: "Male",
    };
    sheet["D5"] = {
      v: "Female",
    };
    sheet["E5"] = {
      v: "Total",
    };
    sheet["F5"] = {
      v: "Male",
    };
    sheet["G5"] = {
      v: "Female",
    };
    sheet["H5"] = {
      v: "Total",
    };
    sheet["I5"] = {
      v: "Male",
    };
    sheet["J5"] = {
      v: "Female",
    };
    sheet["K5"] = {
      v: "Total",
    };
    sheet["L5"] = {
      v: "Male",
    };
    sheet["M5"] = {
      v: "Female",
    };
    sheet["N5"] = {
      v: "Total",
    };
    sheet["O5"] = {
      v: "Male",
    };
    sheet["P5"] = {
      v: "Female",
    };
    sheet["Q5"] = {
      v: "Total",
    };

    XLSX.utils.book_append_sheet(wb, sheet, "report-student-strength");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:G2",
      tbodyRange: `A3:AA${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:AA${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(10);
        sheet.column("D").width(10);
        sheet.column("E").width(10);
        sheet.column("F").width(10);
        sheet.column("G").width(10);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Exam Details Cluster Wise Grade Summary
          </Typography>
        </Stack>
        <Box width={300}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/**/}
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Session"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="session"
            onChange={_handleOnChange}
            size="small"
          >
            <MenuItem value="2015-2016">2015-2016</MenuItem>
            <MenuItem value="2016-2017">2016-2017</MenuItem>
            <MenuItem value="2017-2018">2017-2018</MenuItem>
            <MenuItem value="2018-2019">2018-2019</MenuItem>
            <MenuItem value="2019-2020">2019-2020</MenuItem>
            <MenuItem value="2020-2021">2020-2021</MenuItem>
            <MenuItem value="2021-2022">2021-2022</MenuItem>
            <MenuItem value="2022-2023">2022-2023</MenuItem>
            <MenuItem value="2023-2024">2023-2024</MenuItem>
            <MenuItem value="2024-2025">2024-2025</MenuItem>
            <MenuItem value="2025-2026">2025-2026</MenuItem>
            <MenuItem value="2026-2027">2026-2027</MenuItem>
            <MenuItem value="2027-2028">2027-2028</MenuItem>
            <MenuItem value="2028-2029">2028-2029</MenuItem>
            <MenuItem value="2029-2030">2029-2030</MenuItem>
            <MenuItem value="2030-2031">2030-2031</MenuItem>
            <MenuItem value="2031-2032">2031-2032</MenuItem>
            <MenuItem value="2032-2033">2032-2033</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Test Type"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="test_type"
            onChange={_handleOnChange}
            size="small"
          >
            <MenuItem value="WEEKLY">WEEKLY</MenuItem>
            <MenuItem value="MONTHLY">MONTHLY</MenuItem>
            <MenuItem value="MID-TERM">MID-TERM</MenuItem>
            <MenuItem value="ANNUAL">ANNUAL</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <LoadingButton
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Get Student Results
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="">Cluster Name</TableCell>
                  <TableCell align="Center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>A1</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>A</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="Center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>B</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="Center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>C</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="Center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>FAIL</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.length > 0 &&
                  results.map((item, index) => (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.regionName}</TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>{item.GradeA1Male}</Grid>
                            <Grid xs={4}>{item.GradeA1Female}</Grid>
                            <Grid xs={4}>{item.TotalA1Students}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>{item.GradeAMale}</Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeAFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalAStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>{item.GradeBMale}</Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeBFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalBStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>{item.GradeCMale}</Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeCFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalCStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>{item.GradeFAILMale}</Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeFAILFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalFAILStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.TotalFAILStudents +
                                item.TotalCStudents +
                                item.TotalBStudents +
                                item.TotalAStudents +
                                item.TotalA1Students}
                            </Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
