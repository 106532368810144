import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
// import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MuiAlert from "@mui/material/Alert";
import useSession from "../../CustomHooks/useSession";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { MinioUrl } from "./../../Api/axios.instance";
import { use } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Task({}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolID, setSchoolID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [schoolId, setSchoolId] = useState(null);
  const [formData, setFormData] = useState({
    AssignedBy: "",
    AssignedTo: "",
    SchoolID: null,
    TaskDescription: "",
    CurrentState: "Open",
    Priority: "",
    StartDate: dayjs().format("YYYY-MM-DD"),
    ApproximateCloseDate: dayjs().format("YYYY-MM-DD"),
    ActualCloseDate: dayjs().format("YYYY-MM-DD"),
    CreatedAt: dayjs().format("YYYY-MM-DD"),
    UpdateddAt: dayjs().format("YYYY-MM-DD"),
    TimeSpent: "",
    CommentText: "",
    CommentDate: dayjs().format("YYYY-MM-DD"),
    FilePath: "",
    UploadedAt: dayjs().format("YYYY-MM-DD"),
  });
  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [users, setUsers] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [imgBase, setImgBase] = useState("");
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const openAnchor = Boolean(anchorEl);
  const [task, setTask] = useState([]);
  const [selectedTaskList, setSelectedTaskList] = useState({});
  const [uID, setUID] = useState(null);
  const [isCruding, setIsCruding] = useState(false);
  useEffect(() => {
    _fetchUsers();
    _fetchTasks();
  }, []);

  const _fetchUsers = async () => {
    try {
      const { data, status } = await axiosInstance.get("/users");
      if (status === 200) {
        setUsers(data.user);
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const _onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openEditMode = (task) => {
    setIsEditMode(true);
    // setSelectedTaskList(task);
    setOpenEdit(true);
  };

  const openCreateMode = () => {
    setIsEditMode(false);
    setFormData({ CurrentState: "Open" });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter(
      (file) => file.size <= 2 * 1024 * 1024
    );

    if (validFiles.length < selectedFiles.length) {
      alert("Some files were too large and not added (max size: 2MB).");
    }

    const uploadedFiles = [];
    setFiles1(validFiles);
    for (const file of validFiles) {
      const base64 = await convertToBase64(file);
      const previewUrl = file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : null;
      uploadedFiles.push({
        file,
        name: file.name,
        url: base64,
        preview: previewUrl,
      });
    }
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

    setFormData((prevState) => ({
      ...prevState,
      FilePath: uploadedFiles.map((file) => file.name),
    }));
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const _openFileDialog = () => {
    if (imgBase !== "") {
      setImgBase("");
      setFormData("image", "");
      return;
    }
  };

  const submitTaskForm = async () => {
    try {
      setLoading((prevState) => !prevState);
      const SchoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      const UserId = JSON.parse(localStorage.User).id;

      const data = new FormData();
      data.append("AssignedBy", UserId);
      data.append("AssignedTo", formData.AssignedTo);
      data.append("SchoolID", SchoolID);
      data.append("TaskDescription", formData.TaskDescription);
      data.append("CurrentState", formData.CurrentState);
      data.append("Priority", formData.Priority);
      data.append("StartDate", formData.StartDate);

      data.append("CommentText", formData.CommentText);
      data.append("CommentDate", formData.StartDate);

      data.append("FilePath", JSON.stringify(formData.FilePath));
      data.append("UploadedAt", formData.StartDate);

      if (files1 && files1.length > 0) {
        files.forEach((file, index) => {
          data.append(`files`, file.file);
        });
      }

      const response = await axiosInstance.post("/task", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.success) {
        setTimeout(() => {
          handleClose();
          _fetchTasks();
          setLoading((prevState) => !prevState);
        }, 1500);
      } else {
        console.error("Task creation failed:", response.data.message);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.error(
        "Error submitting task form:",
        error.response?.data || error.message
      );
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setFormData({
      AssignedBy: "",
      AssignedTo: "",
      SchoolID: null,
      TaskDescription: "",
      CurrentState: "Open",
      Priority: "",
      StartDate: dayjs().format("YYYY-MM-DD"),
      ApproximateCloseDate: dayjs().format("YYYY-MM-DD"),
      ActualCloseDate: dayjs().format("YYYY-MM-DD"),
      CreatedAt: dayjs().format("YYYY-MM-DD"),
      UpdateddAt: dayjs().format("YYYY-MM-DD"),
      TimeSpent: "",
      CommentText: "",
      CommentDate: dayjs().format("YYYY-MM-DD"),
      FilePath: "",
      UploadedAt: dayjs().format("YYYY-MM-DD"),
    });
  };

  const snack_handleClose = () => {
    setSnackOpen(false);
  };

  const _fetchTasks = async () => {
    const searchParams = new URLSearchParams();
    const schoolid = JSON.parse(localStorage.getItem("UserAccess"))[0].SchoolId;

    if (
      accesLevel === "ADMIN" ||
      accesLevel === "SCHOOL" ||
      accesLevel === "CLUSTER"
    ) {
      if (schoolid) {
        searchParams.append("schoolid", schoolid);
      } else {
        console.error("SchoolId not found in localStorage.");
      }
    }

    try {
      const { data, status } = await axiosInstance.get("/task/get-task-list", {
        params: searchParams,
      });
      if (status === 200) {
        setTask(data.data[0]);
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setSelectedTaskList({});
    setFiles([]);
  };

  const _onEdit = async (e) => {
    e.preventDefault();
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/task${selectedTaskList.AssignedBy}`,
        selectedTaskList
      );
      if (status === 200) {
        setLoading((prevState) => !prevState);
        // setSnackMessage(data.message);
        // setSnackBar(true);
        setOpenEdit(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.error(error);
    }
  };

  const _handleOnEditChange = (e) => {
    setSelectedTaskList((prevState) => {
      return {
        ...prevState,
        AssignedBy: uID,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (accesLevel === "SCHOOL" || accesLevel === "ADMIN") {
      const accessObject = Object.assign({}, access);
      const UserId = accessObject[0].UserId;
      if (UserId === undefined) {
        const getUserID = JSON.parse(localStorage.getItem("User"));
        setUID(getUserID.id);
      } else {
        setUID(UserId);
      }
    }
  }, []);

  const handleClick = (item) => (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedTaskList(item);
    // openEditMode(item);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const createFileFromName = async (fileName, fileUrl) => {
    try {
      const response = await fetch(fileUrl); // Fetch file data from the URL
      const blob = await response.blob(); // Convert response to a Blob

      // Create a File object using the Blob
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    } catch (error) {
      console.error("Error creating file:", error);
      return null;
    }
  };

  const recreateFiles = async (fileData) => {
    const files = [];

    for (const x of fileData) {
      const url = MinioUrl + "/taskdocs/" + x;
      const file = await createFileFromName(x, url);

      if (file) {
        const base64 = await convertToBase64(file);
        const previewUrl = file.type.startsWith("image/")
          ? URL.createObjectURL(file)
          : null;
        console.log(previewUrl, "previewUrl");
        files.push({
          file,
          name: file.name,
          url: base64,
          preview: previewUrl,
        });
      }
    }

    return files;
  };

  async function fetchFiles(fileNames) {
    const recreatedFiles = await recreateFiles(fileNames);
    setFiles(recreatedFiles);
  }

  useEffect(() => {
    console.log(files);
  }, [files]);

  useEffect(() => {
    if (selectedTaskList.Attachments) {
      const Names = selectedTaskList.Attachments.split(",");
      fetchFiles(Names);
    }
  }, [selectedTaskList.Attachments]);

  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Task Management Module
            </Typography>
            <Typography variant="subtitle2" component="span">
              Manage Task for respective schools and their departments.
            </Typography>
          </Stack>
          <Box width={300}>
            {currentPageCRUD.create && (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Add Task
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">School Name</TableCell>
                  <TableCell align="left">Task Assignee</TableCell>
                  <TableCell align="center">Task Owner Name</TableCell>
                  <TableCell align="center">Task Description</TableCell>
                  <TableCell align="center">Current State</TableCell>
                  <TableCell align="right">Start Date</TableCell>
                  <TableCell align="right">Comment </TableCell>
                  <TableCell align="right">Comment By</TableCell>
                  <TableCell align="right">Attachment </TableCell>
                  <TableCell align="right">Uploaded By </TableCell>
                  <TableCell align="right">Priority </TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {task.length > 0 &&
                  task.map((item, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {item.NameOfSchool}
                        </TableCell>
                        <TableCell align="center">{item.AssignedTo}</TableCell>
                        <TableCell align="center">{item.AssignedBY}</TableCell>
                        <TableCell align="right">
                          {item.TaskDescription}
                        </TableCell>
                        <TableCell align="right">{item.CurrentState}</TableCell>
                        <TableCell align="right">
                          {new Date(item.StartDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="right">{item.CommentText}</TableCell>
                        <TableCell align="right">{item.CommentedBy}</TableCell>
                        <TableCell align="right">{item.Attachments}</TableCell>
                        <TableCell align="right">{item.AssignedBY}</TableCell>
                        <TableCell align="right">{item.Priority}</TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(item)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {currentPageCRUD.update && (
                <MenuItem onClick={() => openEditMode(task)}>Edit</MenuItem>
              )}
            </Menu>
          </TableContainer>
          <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle>Add Task</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 0, mb: 2 }} />
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        inputFormat="DD-MM-YYYY"
                        label="Start Date"
                        value={formData.StartDate}
                        name="StartDate"
                        onChange={_onHandleChange}
                        renderInput={(params) => (
                          <TextField
                            sx={{ display: "flex", flex: 1 }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      autoFocus
                      label="Task Description"
                      variant="outlined"
                      value={formData.TaskDescription}
                      size="small"
                      sx={{ display: "flex", flex: 1 }}
                      name="TaskDescription"
                      onChange={_onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Assigned To"
                      value={formData.AssignedTo}
                      variant="outlined"
                      size="small"
                      sx={{ display: "flex", flex: 1 }}
                      name="AssignedTo"
                      onChange={(event) => _onHandleChange(event)}
                    >
                      {users.length > 0 ? (
                        users.map((user, index) => (
                          <MenuItem key={index} value={user.id}>
                            {`${user.username} (${user.AccessLevel})`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No users available</MenuItem>
                      )}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="current-state-select"
                      select
                      label="Current State"
                      variant="outlined"
                      size="small"
                      sx={{ display: "flex", flex: 1 }}
                      name="CurrentState"
                      value={formData.CurrentState}
                      onChange={_onHandleChange}
                      disabled
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="InProgress">In Progress</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                      <MenuItem value="Blocked">Blocked</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="priority-select"
                      select
                      label="Priority"
                      variant="outlined"
                      size="small"
                      sx={{ display: "flex", flex: 1 }}
                      name="Priority"
                      value={formData.Priority}
                      onChange={_onHandleChange}
                    >
                      <MenuItem value="Low">Low</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                      <MenuItem value="Critical">Critical</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Comment"
                      variant="outlined"
                      size="small"
                      sx={{ display: "flex", flex: 1 }}
                      name="CommentText"
                      value={formData.CommentText}
                      onChange={_onHandleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      type="file"
                      variant="outlined"
                      size="small"
                      // name="FilePath"
                      // value={formData.FilePath}
                      inputProps={{
                        multiple: true,
                        accept:
                          "image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.doc,.docx",
                      }}
                      onChange={handleFileChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box>
                      {files.map((fileObj, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 1,
                            p: 1,
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        >
                          {fileObj.preview ? (
                            <img
                              src={fileObj.preview}
                              alt={fileObj.file.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <span>{fileObj.file.name}</span>
                          )}
                          <LoadingButton
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => removeFile(index)}
                          >
                            Remove
                          </LoadingButton>
                        </Box>
                      ))}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={submitTaskForm}
                      disabled={loading}
                    >
                      Add Task
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="sm"
            open={openEdit}
            onClose={handleEditClose}
          >
            <DialogTitle>Edit Task</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onEdit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Start Date"
                          value={selectedTaskList.StartDate}
                          name="StartDate"
                          disabled
                          onChange={_onHandleChange}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        autoFocus
                        label="Task Description"
                        variant="outlined"
                        value={selectedTaskList.TaskDescription}
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="TaskDescription"
                        onChange={_onHandleChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Assigned To"
                        variant="outlined"
                        value={selectedTaskList.AssignedTo}
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="AssignedTo"
                        onChange={(event) => _onHandleChange(event)}
                        disabled
                      >
                        {users.length > 0 ? (
                          users.map((user, index) => (
                            <MenuItem key={index} value={user.username}>
                              {`${user.username} (${user.AccessLevel})`}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No users available</MenuItem>
                        )}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        id="current-state-select"
                        select
                        label="Current State"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="CurrentState"
                        value={selectedTaskList.CurrentState}
                        onChange={_onHandleChange}
                      >
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="InProgress">In Progress</MenuItem>
                        <MenuItem value="Closed">Closed</MenuItem>
                        <MenuItem value="Blocked">Blocked</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        id="priority-select"
                        select
                        label="Priority"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Priority"
                        value={selectedTaskList.Priority}
                        onChange={_onHandleChange}
                        disabled
                      >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Critical">Critical</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Comment"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="CommentText"
                        value={selectedTaskList.CommentText}
                        onChange={_onHandleChange}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        type="file"
                        variant="outlined"
                        size="small"
                        name="FilePath"
                        value={selectedTaskList.FilePath}
                        inputProps={{
                          multiple: true,
                          accept:
                            "image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.doc,.docx",
                        }}
                        disabled
                        onChange={handleFileChange}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Box>
                        {files.map((fileObj, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mb: 1,
                              p: 1,
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          >
                            {fileObj.preview ? (
                              <img
                                src={fileObj.preview}
                                alt={fileObj.file.name}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <span>{fileObj.file.name}</span>
                            )}
                            <LoadingButton
                              variant="contained"
                              color="error"
                              size="small"
                              disabled
                              onClick={() => removeFile(index)}
                            >
                              Remove
                            </LoadingButton>
                          </Box>
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={submitTaskForm}
                        disabled={loading}
                      >
                        Update Task
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Task has been Added.
        </Alert>
      </Snackbar>
    </>
  );
}
